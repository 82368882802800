import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';
import Layout from '@/components/Layout/Layout';
import TyreHeroSec from '@motokiki/shared/guidesHeroSec';
import GeneralTextBlock from '@/components/GeneralTextBlock';
import TyreIndexCard from '@/components/Cards/TyreIndexCard';
import SearchWidgetContainer from '@/components/SearchWidgetContainer';

import './TyreIndex.scss';
import SEO from '@/components/SEO/Seo';


interface Props {
  data: {
    strapi: {
      tyresIndex: {
        Seo: {
          description: string;
          title: string;
        };
        TextBlock: {
          textBlock: string;
        };
        TyreHeroSection: {
          coverIcon: {
            url: string;
          };
          coverImg: {
            url: string;
          };
          headerText: string;
          iconActive: string;
        };
        TyreCardList: {
          TyreCard: {
            map: (info: {}) => any;
            cardImg: {
              url: string;
              alternativeText: string;
            };
            id: string;
            cardTitle: string;
            cardText: string;
            cardLink: string;
          };
        };
      };
    };
  };
}

const TyreIndex = ({data}: Props) : ReactElement => {

  const { tyresIndex } = data.strapi;
  const { Seo } = tyresIndex

  return (
    <>
    <SEO
      title={Seo.title}
      description={Seo.description}
    />
    <Layout>    
      <TyreHeroSec
        data={tyresIndex.TyreHeroSection}
      />
      <div className="tyreIndexContainer">
        <GeneralTextBlock textData={tyresIndex.TextBlock} />
      </div>
      <div className="tyreInfoSection">
        <h3>Tyre Information</h3>
        <ul className="listOfTyreInfo">
          {tyresIndex.TyreCardList.TyreCard.map((info) => (
            <TyreIndexCard key={info.id} cardData={info} />
          ))}
        </ul>
      </div>
      <SearchWidgetContainer />
    </Layout>
    </>
  );
};

export const pageQuery = graphql`
  query TyreIndex {
    strapi {
      tyresIndex(publicationState: PREVIEW) {
        Seo {
          description
          title
        }
        TextBlock {
          textBlock
        }
        TyreHeroSection {
          coverIcon {
            url
          }
          coverImg {
            url
          }
          headerText
          iconActive
        }
        TyreCardList {
          TyreCard {
            cardImg {
              url
              alternativeText
            }
            id
            cardTitle
            cardText
            cardLink
          }
        }
      }
    }
  }
`;

export default TyreIndex;
